/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Redirect, Route, Switch } from "react-router-dom";

import Footer from "../components/Footer.js";
import Sidebar from "../components/Sidebar.js";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import { agentRoutes } from "../../../routes";
// import ProtectedRoute from "../../general/components/protected-route";
import ClosingDetails from "../pages/closing-details-for-agents";
import Notifications from "../pages/notifications";
import ContactUs from "../pages/contact-us";
import CreateClosing from "../pages/create-closing-agent";
import NewClosingAgent from "../pages/new-closing-agent";
import FAQAGENT from "../pages/faq-agent";
import Settings from "../pages/settings-agent";
import ProtectedRoute from "../../general/components/protected-route";
import BillingAgent from "../pages/billing-agent";
import NotarialLog from "../pages/NotarialLog";
import TopicDetails from "../pages/HelpModule/TopicDetails";
import TopicList from "../pages/HelpModule/TopicList";
import Help from "../pages/HelpModule/Help";
import AdminNavbar from "../components/AdminNavbar.js";
import { updateUserObj } from "../../../redux/actions/user-data";
import { connect } from "react-redux";
import BulkUpload from "../pages/bulk-upload.jsx";
import LinkedInPage from "../pages/linkedinPage.jsx";
import Gleap from "gleap";
import { GLEAP_AGENT_ID } from "../../../config/index.js";
import {
  getAgentProfileDetail,
  updateAgentProfileDetail,
} from "../../../http/http-calls.js";
import ReKYCCard from "../components/re-kycalert.jsx";
import { errorHandler } from "../../../helper-methods/index.js";
import AgentQueryCenter from "../pages/AgentQueryCenter.jsx";
import AgentCompanyCreditCard from "../pages/AgentPages/AgentCompanyCreditCard.jsx";
// import GoogleSearchConsole from "../pages/google-console.jsx";

var ps;

class AgentLayout extends React.Component {
  state = {
    backgroundColor: "black",
    activeColor: "info",
    sidebarMini: false,
    showKycBanner: false,
  };

  componentDidMount() {
    // Gleap.initialize(GLEAP_AGENT_ID);

    // Only for dev
    // App name => agent testing
    Gleap.initialize("BU4juuQDXNRonV1T2hl6HaWHspacv0h1");

    // // Show Gleap in this page
    Gleap.showFeedbackButton(true);

    // // // For CheckList
    // Gleap.openChecklists();

    // // // Only Load for Agent
    // Gleap.startChecklist(GLEAP_CHECKLIST_OUTBOUNDID);

    // if (navigator.platform.indexOf("Win") > -1) {
    //   document.documentElement.className += " perfect-scrollbar-on";
    //   document.documentElement.classList.remove("perfect-scrollbar-off");
    //   ps = new PerfectScrollbar(this.refs.mainPanel);
    // }

    this._getAgentProfileDetail();
  }

  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    //   document.documentElement.className += " perfect-scrollbar-off";
    //   document.documentElement.classList.remove("perfect-scrollbar-on");
    // }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      // if (prop.layout === "/admin") {
      return (
        <ProtectedRoute
          path={prop.path}
          component={prop.component}
          key={key}
          redirectRoute={"/login"}
        />
      );
    });
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  _getAgentProfileDetail = () => {
    getAgentProfileDetail()
      .then((res) => {
        this.props.updateUserObj(res?.agent || null);
        // this.setState({
        // showKycBanner: res?.agent?.showKycBanner,
        // });
        // resolve(res);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _hideReKYCBanner = async () => {
    const { userData } = this.props;
    // updateSigningCompanyProfile
    let response = await updateAgentProfileDetail(userData.user.id, {
      showKycBanner: false,
    });
    if (response) {
      this.props.updateUserObj({ showKycBanner: false });
      // this.setState({
      // showKycBanner: false,
      // });
    }
    try {
    } catch (error) {
      errorHandler(error);
    }
  };

  render() {
    const { userData } = this.props;
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={agentRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            userData={userData}
            handleMiniClick={this.handleMiniClick}
          />
          {userData?.user?.showKycBanner ? (
            <ReKYCCard
              hideReKYCBanner={() =>
                // this.setState({ showKycBanner: false })
                this.props.updateUserObj({ showKycBanner: false })
              }
              doNotShowAgain={() => this._hideReKYCBanner()}
            />
          ) : null}
          <Switch>
            {this.getRoutes(agentRoutes)}

            <ProtectedRoute
              path={`${this.props.match.path}/closing-details-for-agents/:id`}
              component={ClosingDetails}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              path={`${this.props.match.path}/reporting/notarial-log`}
              component={NotarialLog}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/create-closing`}
              component={CreateClosing}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/new-closing/:id`}
              component={NewClosingAgent}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/notifications`}
              component={Notifications}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/query-center`}
              component={AgentQueryCenter}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/contact`}
              component={ContactUs}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/billing`}
              component={BillingAgent}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/credit-card`}
              component={AgentCompanyCreditCard}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/faq`}
              component={FAQAGENT}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/help`}
              component={Help}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/topic-list/:id`}
              component={TopicList}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/topic/:id`}
              component={TopicDetails}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/settings`}
              component={Settings}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              path={`${this.props.match.path}/social-media-management/linkedin/`}
              component={LinkedInPage}
              redirectRoute={"/login"}
            />
            <ProtectedRoute
              path={`${this.props.match.path}/bulk-upload`}
              component={BulkUpload}
              redirectRoute={"/login"}
            />
            {/* <ProtectedRoute
              path={`${this.props.match.path}/console`}
              component={GoogleSearchConsole}
              redirectRoute={"/login"}
            /> */}

            <Route path="/" render={() => <Redirect to="/agent/dashboard" />} />
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (userData) => dispatch(updateUserObj(userData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentLayout);
