import React from "react";
import {
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Badge,
  Button,
  Label,
  Input,
  NavbarBrand,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import {
  checkPermission,
  deepClone,
  errorHandler,
  isRegularUser,
  logout,
  openUrlOnNewTab,
  structureQueryParams,
} from "../../../helper-methods";
import {
  removeDeviceId,
  getAllNotifications,
  uploadAgentCSV,
  uploadClientCSV,
} from "../../../http/http-calls";
import { showToast } from "../../../helper-methods";
import { connect } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";
import { disconnectToSocket } from "../../../socket-io";
import {
  signingCompanyRoutes,
  agentRoutes,
  clientRoutes,
} from "../../../routes";
import {
  // dispatchResetFilterEvent,
  HeaderEventEmitter,
} from "../../../helper-methods/HeaderEvents";
import UpgradeAccountModal from "./Modals/upgradeAccountModal";
import BulkUploadInfo from "./BulkUploadInfo";
import CSVReader from "react-csv-reader";
import { Link } from "react-router-dom";
import { app_logo, BASE_URL } from "../../../config";
import { paths } from "../../../config/path-config";
import SvgIcons from "./SvgIcons";

const parseOptions = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
};

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      unreadnotificationsCount: 0,
      upgradeAccountModal: {
        isOpen: false,
        data: null,
      },
      bulkUploadResponse: {
        isOpen: false,
        data: null,
        success: null,
      },
      loading: {
        bulkFile: false,
        closingLoading: false,
      },
      selectedFile: {},
      closingDetails: null,
    };
  }

  _getAllNotifications = async () => {
    try {
      const { userData } = this.props;

      const response = await getAllNotifications();

      if (response) {
        userData.user.unreadnotificationsCount = response?.unreadMsgCount;

        this.props.updateUserObj(userData?.user);
        this.setState({ unreadnotificationsCount: response?.unreadMsgCount });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateColor);

    this._getAllNotifications();
  };

  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  _onLogout = async () => {
    try {
      await removeDeviceId({ deviceId: localStorage.getItem("deviceId") });
    } catch (error) {
      errorHandler(error);
    }
    this._disconnectToSocket();
    logout(this.props.history);
  };

  _disconnectToSocket = () => {
    disconnectToSocket()
      .then((flag) => {
        console.log("disconnected>>", flag);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _redirectToNotificationPage = () => {
    const { userData } = this.props;

    this.props.history.push(`/${userData.type}/notifications`);
  };

  _redirectToFaqPage = () => {
    const { userData } = this.props;

    this.props.history.push(`/${userData.type}/faq`);
  };

  _redirectToSettingsPage = () => {
    const { userData } = this.props;

    this.props.history.push(`/${userData.type}/settings`);
  };

  _redirectToAgentQueryPage = () => {
    if (!isRegularUser()) {
      this._toggleUpgradeAccountModal(true);
    } else {
      this.props.history.push(`/agent/query-center`);
    }
  };

  _getComponentName = (routes) => {
    for (const route of routes) {
      if (route.path === this.props.location.pathname) {
        return route.headerName;
      } else if (route.views) {
        const nestedName = this._getComponentName(route.views);
        if (nestedName) {
          return nestedName;
        }
      }
    }
    return this._checkForOtherRoute();
  };

  _checkForOtherRoute = () => {
    const { closingDetails } = this.state;
    const { pathname } = this.props.location;

    // const subscriptionCallback = (closingData) => {
    //   this.setState({
    //     closingDetails: closingData?.closingDetails,
    //   });
    // };

    // HeaderEventEmitter.subscribe("closing-details", subscriptionCallback);

    switch (true) {
      case pathname.includes("/agent/closing-details-for-agents/"):
        return (
          <>
            Order Details
            <span>Order No #{closingDetails?.closingNumber || "N/A"}</span>
          </>
        );
      case pathname.includes("/signingcompany/closing-details/"):
        return (
          <>
            Order Details
            <span>Order No #{closingDetails?.closingNumber || "N/A"}</span>
          </>
        );

      case pathname.includes("/client/order-details/"):
        const createdBy =
          closingDetails?._createdBy?.name?.full ||
          closingDetails?._createdByClient?.name?.full;
        if (createdBy) {
          return (
            <>
              Order Details
              <span>Created by {createdBy}</span>
            </>
          );
        }
        return <>Order Details</>;

      case pathname.includes("/client/signing-company-details/"):
        return <>Company Details</>;

      case pathname.includes("/signingcompany/agent-details/"):
        return <>Agent Details</>;

      case pathname.includes("/signingcompany/client-details/"):
        return <>Client Details</>;

      case pathname.includes("/agent/bulk-upload"):
        return <>Bulk Upload</>;

      case pathname.includes("/agent/help"):
        return <>Help</>;

      // case pathname.includes("/agent/console"):
      //   return <>Google Search console</>;

      case pathname.includes("/agent/topic-list/"):
        return <>Topics</>;

      case pathname.includes("/agent/topic/"):
        return <>Topic Details</>;

      case pathname.includes("/agent/query-center"):
        return <>Query Center</>;

      case pathname.includes("/agent/new-closing/"):
        // return <></>;
        return (
          <>
            New Signing Offer
            <span>Order No #{closingDetails?.fileNumber || "N/A"}</span>
          </>
        );

      case pathname.includes("/agent/marketing/social-media-management"):
        return <>Social Media Management</>;

      default:
        return null;
    }
  };
  _handleBulkUploadFile = (data, fileInfo, originalFile) => {
    console.log("sds", data, originalFile);
    // this.props.history.push("/candidate-bulk-upload");
    HeaderEventEmitter.dispatch("bulkUpload", { data, originalFile });
  };

  _renderButtonJSX = () => {
    const { loading, closingDetails } = this.state;
    const { pathname } = this.props.location;

    const userType = this.props.userData?.user?.type?.toLowerCase();

    const subscriptionCallback = (closingData) => {
      console.log("dd", closingData);

      this.setState({
        closingDetails: closingData?.closingDetails,
      });
    };
    const closingLoading = (data) => {
      this.setState({
        loading: {
          ...this.state.loading,
          closingLoading: data,
        },
      });
    };
    const emailLoading = (data) => {
      this.setState({
        loading: {
          ...this.state.loading,
          isEmailLoading: data,
        },
      });
    };

    HeaderEventEmitter.subscribe("closing-details", subscriptionCallback);
    HeaderEventEmitter.subscribe("confirmationFileLoading", closingLoading);
    HeaderEventEmitter.subscribe("isEmailLoading", emailLoading);

    if (
      pathname.includes("/agent/closing-details-for-agents/") ||
      pathname.includes("/signingcompany/closing-details/")
    ) {
      return (
        <>
          {pathname.includes("/signingcompany/closing-details/") ? (
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("track-order")}
              disabled={!closingDetails?.shippingCompany?.trim()}
            >
              Track Shipment
            </Button>
          ) : null}
          <Button
            color="primary"
            onClick={() => HeaderEventEmitter.dispatch("download-invoice")}
            disabled={
              closingDetails?.status === "Cancelled" ||
              closingDetails?.status === "CCA" ||
              closingDetails?.status === "Arrived"
            }
          >
            Invoice
          </Button>
          <Button
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/${userType}/create-closing?${closingDetails?.id}${
                  userType === "signingcompany" ? "&isClone=true" : ""
                }`
              )
            }
          >
            Clone
          </Button>
        </>
      );
    }

    if (pathname.includes("/client/order-details/")) {
      return (
        <>
          <Button
            color="primary"
            onClick={() => HeaderEventEmitter.dispatch("track-order")}
            disabled={!closingDetails?.shippingCompany?.trim()}
          >
            Track Shipment
          </Button>
          <Button
            color="primary"
            onClick={() =>
              this.props.history.push(
                `/client/create-order?${closingDetails.id}`
              )
            }
          >
            Clone
          </Button>
          <Button
            color="primary"
            onClick={() =>
              HeaderEventEmitter.dispatch("download-invoice-client")
            }
            disabled={
              closingDetails?.status === "Cancelled" ||
              closingDetails?.status === "CCA" ||
              closingDetails?.status === "Arrived"
            }
          >
            Download Invoice
          </Button>
        </>
      );
    }

    if (pathname.includes("/bulk-upload")) {
      return (
        <>
          <CSVReader
            cssClass="uploadBtn"
            // onFileLoaded={this._handleForce}
            onFileLoaded={this._handleBulkUploadFile}
            parserOptions={parseOptions}
            inputStyle={{ display: "none" }}
            label={
              <>
                <img
                  src={require("../../../assets/img/uploadIcon.svg").default}
                  alt="bulk upload"
                />{" "}
                Bulk Upload
              </>
            }
          />
        </>
      );
    }

    if (pathname.includes("/agent/marketing/social-media-management")) {
      return (
        <>
          <Button
            color="link"
            onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
            id="agent-social-media-management"
            className="resetBtn"
          >
            <SvgIcons type={"refresh"} />
          </Button>
          <Button
            color="primary"
            // onClick={() => this._toggleAddClientModal(true)}
            onClick={() => HeaderEventEmitter.dispatch("add-post", true)}
          >
            Add Post
          </Button>
        </>
      );
    }
    switch (this.props.location.pathname) {
      case "/agent/closing-master-schedule":
        return (
          <>
            <Button
              // onClick={() => dispatchResetFilterEvent()}
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="agent-closing-master-schedule"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() =>
                isRegularUser()
                  ? HeaderEventEmitter.dispatch("calendar-sync")
                  : this._toggleUpgradeAccountModal(true)
              }
            >
              <img
                src={require("../../../assets/img/Google-calandar.png")}
                alt="google calandar"
                height={18}
              />{" "}
              Connect google Calendar
            </Button>
          </>
        );
      case "/signingcompany/closing-master-schedule":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="company-closing-master-schedule"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("calendar-sync")}
            >
              <img
                src={require("../../../assets/img/Google-calandar.png")}
                alt="google calandar"
                height={18}
              />{" "}
              Connect google Calendar
            </Button>
          </>
        );
      case "/agent/dashboard":
        return (
          <>
            <Button
              color="link"
              className="resetBtn"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="agent-dashboard"
            >
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              outline
              className="my-0"
              onClick={() => {
                if (!isRegularUser()) {
                  this._toggleUpgradeAccountModal(true);
                } else {
                  HeaderEventEmitter.dispatch("import-modal");
                }
              }}
            >
              Import
            </Button>
            <Button
              color="primary"
              className="my-0"
              onClick={() => HeaderEventEmitter.dispatch("create-closing")}
            >
              {" "}
              Create
            </Button>
          </>
        );
      case "/signingcompany/closing-dashboard":
        return (
          <>
            <UncontrolledDropdown>
              <DropdownToggle caret>Export</DropdownToggle>
              <DropdownMenu right>
                {!this.props.userData?.user?.isTitleCompany && (
                  <DropdownItem
                    onClick={() =>
                      HeaderEventEmitter.dispatch("quickbooks-invoice")
                    }
                  >
                    Export Invoice
                  </DropdownItem>
                )}
                <DropdownItem
                  onClick={() => HeaderEventEmitter.dispatch("quickbooks-bill")}
                >
                  Export Bill
                </DropdownItem>
                <DropdownItem
                  onClick={async () => {
                    try {
                      const params = {
                        token: this.props.userData?.token,
                      };

                      const queryString = structureQueryParams(params);
                      openUrlOnNewTab(
                        `${BASE_URL}/signingcompany/export/agents${queryString}`
                      );
                    } catch (err) {
                      errorHandler(err);
                    }
                  }}
                >
                  Export Vendor
                </DropdownItem>
                <DropdownItem
                  onClick={async () => {
                    try {
                      const params = {
                        token: this.props.userData?.token,
                      };

                      const queryString = structureQueryParams(params);
                      openUrlOnNewTab(
                        `${BASE_URL}/signingcompany/export/client${queryString}`
                      );
                    } catch (err) {
                      errorHandler(err);
                    }
                  }}
                >
                  Export Clients
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="company-closing-dashboard"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type="refresh" />
            </Button>
            <Button
              color="primary"
              outline
              className="my-0"
              onClick={() => {
                HeaderEventEmitter.dispatch("import-modal");
              }}
            >
              Import
            </Button>
            {checkPermission("dashboard", "canEditOrderEntry") && (
              <Button
                color="primary"
                onClick={() => HeaderEventEmitter.dispatch("create-closing")}
              >
                Create
              </Button>
            )}
          </>
        );
      case "/client/order-dashboard":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="client-order-dashboard"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>

            {checkPermission("dashboard", "canCreateOrder") && (
              <Button
                color="primary"
                onClick={() => HeaderEventEmitter.dispatch("create-closing")}
              >
                {" "}
                Create
              </Button>
            )}
          </>
        );
      case "/signingcompany/agents":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              className="resetBtn"
              id="company-agents"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>

            <Label className="uploadBtn outline mr-3">
              <Input
                type="file"
                hidden
                accept=".csv"
                value=""
                disabled={loading.bulkFile}
                onChange={(e) =>
                  this._onFileSelect("selectedFile", e, "Agents")
                }
              />
              {loading.bulkFile ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <>
                  <SvgIcons type={"upload"} />
                  Upload
                </>
              )}
            </Label>
            {/* <Link
              className="uploadBtn outline ml-2 mr-3"
              outline
              to={`/signingcompany/bulk-upload?companyAgent`}
            >
              Bulk upload
            </Link> */}
            {checkPermission("agents", "canAdd") && (
              <Button
                color="primary"
                onClick={() => HeaderEventEmitter.dispatch("add-agent")}
              >
                New Agent
              </Button>
            )}
          </>
        );

      case "/agent/marketing/clients":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="agent-clients"
              className="resetBtn"
            >
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("add-client", true)}
            >
              Add Client
            </Button>
            <Link
              className="uploadBtn outline ml-2"
              outline
              to={`/agent/bulk-upload?agentClient`}
            >
              Bulk upload
            </Link>
          </>
        );
      case "/agent/marketing/templates":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="agent-templates"
              className="resetBtn"
            >
              {/* <img
              
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("add-template", true)}
            >
              Add Templates
            </Button>
          </>
        );
      case "/agent/create-closing": {
        return (
          <Label
            className="uploadBtn fs-12"
            for="uploadAutomation"
            onClick={() =>
              !isRegularUser() && this._toggleUpgradeAccountModal(true)
            }
          >
            {loading.closingLoading ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <>
                <img
                  src={require("../../../assets/img/uploadIcon.svg").default}
                  alt="upload automation"
                />{" "}
                UPLOAD AUTOMATION
              </>
            )}
            {isRegularUser() && (
              <Input
                type="file"
                id="uploadAutomation"
                accept=".pdf"
                disabled={loading.closingLoading}
                title="Upload Confirmation"
                value=""
                onChange={(event) =>
                  HeaderEventEmitter.dispatch("closingFile", event)
                }
              />
            )}
          </Label>
        );
      }
      case "/signingcompany/create-closing": {
        return (
          <Label className="uploadBtn fs-12">
            {loading?.closingLoading ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <div>
                <img
                  src={require("../../../assets/img/uploadIcon.svg").default}
                  alt="upload"
                />{" "}
                Upload
              </div>
            )}
            <Input
              type="file"
              id="uploadAutomation"
              accept=".pdf"
              disabled={loading.closingLoading}
              title="Upload Confirmation"
              // multiple
              value=""
              onChange={(event) =>
                HeaderEventEmitter.dispatch("closingFile", event)
              }
            />
          </Label>
        );
      }
      case "/signingcompany/clients":
        return (
          checkPermission("clients", "canAdd") && (
            <div>
              <Label className="uploadBtn outline mr-3">
                <Input
                  type="file"
                  hidden
                  accept=".csv"
                  value=""
                  disabled={loading.bulkFile}
                  onChange={(e) => this._onFileSelect("selectedFile", e)}
                />
                {loading.bulkFile ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <>
                    <SvgIcons type="upload" />
                    Upload
                  </>
                )}
              </Label>

              {/* <Link
                className="uploadBtn outline ml-2 mr-3"
                outline
                to={`/signingcompany/bulk-upload?companyClient`}
              >
                Bulk upload
              </Link> */}

              <Button
                color="primary"
                onClick={() => HeaderEventEmitter.dispatch("add-client", true)}
              >
                Add Client {/* Invite  */}
              </Button>
            </div>
          )
        );
      case "/signingcompany/users":
        return (
          <>
            <Button
              color="link"
              className="resetBtn"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="company-users"
            >
              {/* <img
               
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            {checkPermission("users", "canAdd") && (
              <Button
                color="primary"
                onClick={() =>
                  HeaderEventEmitter.dispatch("add-team-member", true)
                }
              >
                Invite Team Members
              </Button>
            )}
          </>
        );
      case "/agent/subscription":
        return (
          <>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("billing", true)}
            >
              My Billing
            </Button>
            <Button
              color="primary"
              onClick={() =>
                HeaderEventEmitter.dispatch("agentCreditCardRoute", true)
              }
            >
              Card Manager
            </Button>
          </>
        );

      case "/agent/credit-card":
        return (
          <>
            <Button
              color="primary"
              onClick={() =>
                HeaderEventEmitter.dispatch("agentCreditCard", true)
              }
            >
              Add Card
            </Button>
          </>
        );

      case "/agent/query-center":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
            >
              <img
                id="agent-query-center"
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              />
            </Button>
          </>
        );
      case "/signingcompany/subscription":
        return (
          <>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("billing", true)}
            >
              Billing
            </Button>
            <Button
              color="primary"
              onClick={() =>
                HeaderEventEmitter.dispatch("companyCreditCardRoute", true)
              }
            >
              Card Manager
            </Button>
          </>
        );

      case "/signingcompany/credit-card":
        return (
          <>
            <Button
              color="primary"
              onClick={() =>
                HeaderEventEmitter.dispatch("companyCreditCard", true)
              }
            >
              Add Card
            </Button>
          </>
        );

      case "/agent/accounting/income-list":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="agent-accounting-income-list"
              className="resetBtn"
            >
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() =>
                isRegularUser()
                  ? HeaderEventEmitter.dispatch("add-income")
                  : this._toggleUpgradeAccountModal(true)
              }
            >
              {" "}
              Add Income
            </Button>
            <Link
              className="uploadBtn outline mx-2"
              to={`/agent/bulk-upload?paidIncome`}
            >
              Bulk upload Paid
            </Link>
            <Link
              className="uploadBtn outline"
              to={`/agent/bulk-upload?pendingIncome`}
            >
              Bulk upload Pending
            </Link>
          </>
        );

      case "/signingcompany/accounting/income":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="company-accounting-income"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("add-income")}
            >
              {" "}
              Add Income
            </Button>
          </>
        );

      case "/agent/accounting/expenses":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="agent-accounting-expenses"
              className="resetBtn"
            >
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() =>
                isRegularUser()
                  ? HeaderEventEmitter.dispatch("add-expense")
                  : this._toggleUpgradeAccountModal(true)
              }
            >
              {" "}
              Add Expense
            </Button>
            <Link
              className="uploadBtn outline ml-2"
              outline
              to={`/agent/bulk-upload?expenses`}
            >
              Bulk upload
            </Link>
          </>
        );
      case "/signingcompany/accounting/expenses":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="company-accounting-expenses"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("add-expense")}
            >
              {" "}
              Add Expense
            </Button>
          </>
        );
      case "/agent/accounting/invoicing":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="agent-accounting-invoicing"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("add-invoice")}
            >
              {" "}
              New Invoice
            </Button>
          </>
        );
      case "/signingcompany/accounting/invoicing":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="company-accounting-invoicing"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("add-invoice")}
            >
              {" "}
              New Invoice
            </Button>
          </>
        );
      case "/signingcompany/reporting/agent-schedule":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="company-reporting-agent-schedule"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("email")}
              disabled={loading.isEmailLoading}
            >
              {loading.isEmailLoading ? (
                <i className="fa fa-spinner fa-spin mr-2" />
              ) : null}{" "}
              Email
            </Button>
          </>
        );
      case "/signingcompany/accounting/agent-pay-period-breakdown":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="company-accounting-agent-pay-period-breakdown"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
          </>
        );

      case "/agent/reporting/mileage-log":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="agent-reporting-mileage-log"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            <Button
              color="primary"
              onClick={() =>
                isRegularUser()
                  ? HeaderEventEmitter.dispatch("add-mileage")
                  : this._toggleUpgradeAccountModal(true)
              }
            >
              {" "}
              Add Mileage
            </Button>
            <Link
              className="uploadBtn outline ml-2"
              outline
              to={`/agent/bulk-upload?milageLog`}
            >
              Bulk upload
            </Link>
          </>
        );

      case "/client/order-client":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="client-order-client"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            {checkPermission("lenderClient", "canAdd") ? (
              <Button
                // onClick={() => this._toggleAddOrderClient(true)}
                onClick={() => HeaderEventEmitter.dispatch("add")}
                color="primary"
              >
                Add Client
              </Button>
            ) : null}
          </>
        );

      case "/client/client-payment":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="client-client-payment"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
          </>
        );

      case "/client/signing-company":
        return (
          <>
            <Button
              color="link"
              onClick={() => HeaderEventEmitter.dispatch("reset-filter")}
              id="client-signing-company"
              className="resetBtn"
            >
              {/* <img
                src={require("../../../assets/img/refresh.svg").default}
                alt="reset filter"
              /> */}
              <SvgIcons type={"refresh"} />
            </Button>
            {checkPermission("connectCompanies", "canAdd") && (
              <Button
                onClick={() => HeaderEventEmitter.dispatch("invite")}
                color="primary"
              >
                Invite
              </Button>
            )}
          </>
        );

      case "/client/team-member":
        return (
          <>
            {checkPermission("connectCompanies", "canAdd") && (
              <>
                <Button
                  color="primary"
                  onClick={() => HeaderEventEmitter.dispatch("add-team")}
                >
                  Add Team Members
                </Button>
              </>
            )}
          </>
        );
      case "/agent/reporting/notarial-log":
        return (
          <>
            <Link
              className="uploadBtn outline ml-2"
              outline
              to={`/agent/bulk-upload?notarialLog`}
            >
              Bulk upload
            </Link>
            <Button
              color="primary"
              onClick={() => HeaderEventEmitter.dispatch("add-notarial")}
              className="ml-2"
            >
              {" "}
              Add notarial
            </Button>
          </>
        );
      default:
        break;
    }
  };
  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _onFileSelect = (fieldName, e, type) => {
    console.log(e.target.files);
    if (e.target.files?.[0]) {
      this.setState(
        {
          isFormDirty: true,
          [fieldName]: {
            previewBlob: URL.createObjectURL(e.target.files[0]),
            uploadData: e.target.files[0],
          },
        },
        () => {
          if (type === "Agents") {
            this._handleSubmitAgent();
          } else {
            this._handleSubmitClient();
          }
        }
      );
    }
  };

  _handleSubmitClient = async () => {
    this._manageLoading("bulkFile", true);

    try {
      const formData = new FormData();
      formData.append("file", this.state.selectedFile?.uploadData);
      formData.append("companyName", "companyName");
      formData.append("phone", "phone");
      formData.append("generalEmail", "generalEmail");
      formData.append("schedulingEmail", "schedulingEmail");
      formData.append("accountingEmail", "accountingEmail");
      formData.append("personOfContact", "personOfContact");

      let response = await uploadClientCSV(formData);
      this._manageLoading("bulkFile", false);
      if (typeof response.error === "string") {
        errorHandler(response);
      } else {
        !response.error && showToast("Uploaded Successfully", "success");
        this.setState({
          bulkUploadResponse: {
            isOpen: true,
            data: response?.reason,
            success: response?.dataUploadedCount,
          },
        });
      }
    } catch (error) {
      this._manageLoading("bulkFile", false);
      this.setState({
        bulkUploadResponse: {
          isOpen: true,
          data: error?.reason,
          success: error?.dataUploadedCount,
        },
      });
    }
  };
  _handleSubmitAgent = async () => {
    this._manageLoading("bulkFile", true);

    try {
      const formData = new FormData();
      formData.append("file", this.state.selectedFile?.uploadData);
      formData.append("firstName", "firstName");
      formData.append("lastName", "lastName");
      formData.append("email", "email");
      formData.append("phone", "phone");
      formData.append("workType", "workType");
      let response = await uploadAgentCSV(formData);
      this._manageLoading("bulkFile", false);
      // console.log("2222", response);
      if (typeof response.error === "string") {
        errorHandler(response);
      } else {
        !response.error && showToast("Uploaded Successfully", "success");
        this.setState({
          bulkUploadResponse: {
            isOpen: true,
            data: response.reason,
            success: response.dataUploadedCount || null,
          },
        });
      }
    } catch (error) {
      this._manageLoading("bulkFile", false);
      // console.log("444444", error);
      this.setState({
        bulkUploadResponse: {
          isOpen: true,
          data: error.reason,
          success: error.dataUploadedCount || null,
        },
      });
    }
  };
  _onToggleBulkInfoModal = () => {
    let { bulkUploadResponse } = deepClone(this.state);
    bulkUploadResponse.isOpen = !bulkUploadResponse.isOpen;
    bulkUploadResponse.data = null;
    bulkUploadResponse.success = null;
    this.setState({
      bulkUploadResponse,
      selectedFile: {},
    });
  };
  _commonButton = () => (
    <Button
      color="link"
      className="backBtn"
      onClick={() => HeaderEventEmitter.dispatch("back-button")}
    >
      <img
        src={require("../../../assets/img/arrowLeft.svg").default}
        alt="backbutton"
      />
    </Button>
  );
  _renderBackButtonJSX = () => {
    switch (this.props.location.pathname) {
      case "/signingcompany/create-closing":
      case "/agent/create-closing":
      case "/agent/bulk-upload":
      case "/client/create-order":
      case `/agent/closing-details-for-agents/${
        this.props.location.pathname.split(
          "/agent/closing-details-for-agents/"
        )[1]
      }`:
      case `/signingcompany/closing-details/${
        this.props.location.pathname.split(
          "/signingcompany/closing-details/"
        )[1]
      }`:
      case `/client/order-details/${
        this.props.location.pathname.split("/client/order-details/")[1]
      }`:
      case `/agent/topic-list/${
        this.props.location.pathname.split("/agent/topic-list/")[1]
      }`:
      case `/agent/topic/${
        this.props.location.pathname.split("/agent/topic/")[1]
      }`:
      case `/signingcompany/agent-details/${
        this.props.location.pathname.split("/signingcompany/agent-details/")[1]
      }`:
      case `/signingcompany/client-details/${
        this.props.location.pathname.split("/signingcompany/client-details/")[1]
      }`:
      case `/client/signing-company-details/${
        this.props.location.pathname.split(
          "/client/signing-company-details/"
        )[1]
      }`:
        return (
          <>
            <Button
              color="link"
              className="backBtn"
              onClick={() => HeaderEventEmitter.dispatch("back-button")}
            >
              <img
                src={require("../../../assets/img/arrowLeft.svg").default}
                alt="backbutton"
              />
            </Button>
          </>
        );
      default:
        break;
    }
  };
  render() {
    const { upgradeAccountModal, bulkUploadResponse } = this.state;
    const { userData } = this.props;

    return (
      <>
        <Navbar expand="lg">
          <Container fluid>
            <button
              className="navbar-toggler sideBarIcon"
              type="button"
              onClick={this.toggleSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
            <NavbarBrand href="/">
              <img
                src={userData?.user?.logo ? userData.user.logo : app_logo}
                alt="logo"
              />
            </NavbarBrand>

            <div className="headerPageInfo">
              {this._renderBackButtonJSX()}
              <h2 className="pageTitle">
                {this._getComponentName([
                  ...signingCompanyRoutes,
                  ...agentRoutes,
                  ...clientRoutes,
                  ...paths,
                ])}
              </h2>
            </div>

            <div className="d-flex">
              <div className="navigationButton">{this._renderButtonJSX()}</div>
              <Nav navbar>
                {this.props.userData?.user?.type?.toLowerCase() === "agent" ? (
                  <NavItem className="hideMobile">
                    <NavLink
                      className=" cursorPointer"
                      onClick={this._redirectToAgentQueryPage}
                    >
                      <SvgIcons type={"agentQuery"} />
                    </NavLink>
                  </NavItem>
                ) : null}
                <NavItem className="hideMobile">
                  <NavLink
                    className=" cursorPointer"
                    onClick={this._redirectToSettingsPage}
                  >
                    <SvgIcons type={"setting"} />
                  </NavLink>
                </NavItem>
                <NavItem className="hideMobile">
                  <NavLink
                    className="cursorPointer position-relative"
                    onClick={this._redirectToNotificationPage}
                  >
                    <SvgIcons type={"notification"} />

                    {this.props.userData?.user?.unreadnotificationsCount > 0 ? (
                      <Badge color="primary" className="notificationCount">
                        {this.props.userData?.user?.unreadnotificationsCount < 9
                          ? this.props?.userData?.user?.unreadnotificationsCount
                          : "9+"}
                      </Badge>
                    ) : null}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="btn-magnify cursorPointer"
                    onClick={this._onLogout}
                  >
                    <img
                      src={
                        require("../../../../src/assets/img/header/logout.svg")
                          .default
                      }
                      alt="logout"
                    />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Container>
        </Navbar>
        <UpgradeAccountModal
          {...this.props}
          isOpen={upgradeAccountModal.isOpen}
          toggle={this._toggleUpgradeAccountModal}
        />
        <BulkUploadInfo
          isOpen={bulkUploadResponse.isOpen}
          toggle={() => this._onToggleBulkInfoModal()}
          data={bulkUploadResponse.data}
          success={bulkUploadResponse.success}
          type={"Agents"}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (userData) => dispatch(updateUserObj(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
